/**
 * This is an example Listing page that lives on the route '/list-example'
 */
import React, { useEffect } from "react";

import "./styles.css";
import Header from "../../components/header";
import GlobalFooter from "../../components/globalfooter";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { revokeAllTokenFromStarfleet } from "../../api";
const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("ROCP_idToken");
    if (token !== undefined && token !== null && token !== "") {
      revokeAllTokenFromStarfleet().then((data) => {});
    }
    // Remove the 'apphubUserData' cookie by setting its expiration date to the past
    document.cookie = 'apphubUserData=; domain=nvidia.com; path=/; Secure; SameSite=Strict';
    localStorage.clear();
    sessionStorage.clear();
    // Use setTimeout to schedule the redirection after 1 seconds (1000 milliseconds)
    const redirectionTimer = setTimeout(() => {
      navigate("/login");
    }, 1000); // 1000 milliseconds (1 seconds)

    // Clean up the timer if the component unmounts (optional)
    return () => {
      clearTimeout(redirectionTimer);
    };
  }, []);
  return (
    <div>
      <Header
        headerHeading="NVIDIA Application Hub"
        loginHeader={true}
        dashboardHeader={false}
      ></Header>
      <div>
        <div className="signoutbox">
          <div className="login-container">
            <div className="login-box">
              <div class="main-panel bright centered">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
                    fill="#76b900"
                  ></path>
                </svg>
                <div class="main-panel-heading">You are logged out</div>
                <div class="main-panel-message">
                  You have successfully logged out. You can close this tab
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GlobalFooter></GlobalFooter>
    </div>
  );
};
export default Logout;
